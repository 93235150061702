@import '@/styles/_shared.scss';

.featured-subscriptions {
  position: relative;
  display: flex;

  @include breakpoint-down('sm') {
    flex-direction: column;
  }

  &__title {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: $bebas;
    font-size: 44px;
    color: $pureWhite;
    z-index: 2;

    @include breakpoint-down('sm') {
      top: 2%;
      left: 0;
      width: 100%;
      text-align: center;
      transform: unset;
      font-size: 34px;
    }
  }

  &__option-container {
    position: relative;
    width: 50%;

    @include breakpoint-down('sm') {
      width: unset;
      padding: 20px;
      height: 70vh;

      &:first-of-type {
        height: 90vh;
      }
    }

    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 80px;

    & > img {
      object-fit: cover;
      opacity: 0.8;
      z-index: -1;
      filter: blur(1px);
    }

    & > img::after {
      content: '';
      z-index: -1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      pointer-events: none;
    }
  }

  &__option-info-card {
    font-family: $frank-ruhl;
    color: $pureWhite;
    border-radius: 16px;
    background: rgba(29, 48, 66, 0.8);
    width: fit-content;
    padding: 30px;
    max-width: 460px;
    margin-top: 20%;

    @include breakpoint-down('sm') {
      margin-top: 12px;
    }

    display: flex;
    flex-direction: column;
    gap: 18px;

    &__title {
      font-family: $bebas;
      color: $pureWhite;
      font-size: 32px;
      text-align: center;
      margin: 0;
    }

    p {
      font-size: 16px;
    }

    li {
      margin-top: 4px;
    }

    &__action-button {
      width: 100%;
      background: $pureWhite;
      color: $sitkablue;
      border: none;
      padding: 12px;
      border-radius: 8px;
      cursor: pointer;
      font-weight: 500;
    }

    &__info-button {
      color: $pureWhite;
      border: none;
      cursor: pointer;
      background-color: transparent;
      text-decoration: underline;
      font-size: 14px;
    }

    &__product-info {
      color: $pureWhite;
      font-family: $frank-ruhl;
      display: flex;
      justify-content: space-between;

      &__pricing {
        font-weight: 700;
      }
    }
  }
}
